import { Backdrop } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';
import styled from 'styled-components';
import { ReactNode } from 'react';

interface ModalContentProps {
    theme: {
        radius: {
            xs: string
            sm: string
            md: string
        }
    }
};

export const ModalStyl = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalContentStyl = styled.div`
    background-color: ${({ bgNone }: { bgNone:boolean }) => bgNone ? '' : '#fff'};
    padding: 20px;
    border-radius: ${({ theme }: ModalContentProps) => theme.radius.md};
    outline: none;
    user-select: none;
    min-width: 350px;
    max-width: 900px;
    margin: auto;
`;

const useStyles = makeStyles(() =>
    createStyles({
        modal: {
            position: 'absolute',
            top: '10%',
            overflow: 'scroll',
            height: '100%',
            display: 'block',
            padding: 30,
        },
    })
);

interface ModalProps {
    children: ReactNode, 
    open: boolean 
    bgNone?: boolean
    onClose: () => void
};

const ModalComponent = ({ 
    children, 
    open, 
    onClose,
    bgNone,
}: ModalProps) => {
    const classes = useStyles();

    return (
        <ModalStyl
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            className={classes.modal}
        >
            <ModalContentStyl bgNone={bgNone}>{children}</ModalContentStyl>
        </ModalStyl>
    );
};

export default ModalComponent;
