import React, { lazy, Suspense } from "react";
// eslint-disable-next-line no-unused-vars
import { Redirect, Switch } from "react-router-dom";

import ModalOverlay from "../../components/Modal/ModalOverlay/Index";
import RouteWithLayout from "../RouteWithLayout";

const LoginPage = lazy(() => import("../../pages/login/index"));
const RecoverPage = lazy(() => import("../../pages/recover/index"));

const MapPage = lazy(() => import("../../../ts/pages/inspect/index"));
const SecretariatPage = lazy(() =>
    import("../../../ts/pages/secretariat/index")
);

const DashBoardPage = lazy(() => import("../../pages/panel/dashboard"));
const OrderPage = lazy(() => import("../../pages/panel/order"));
const ReportsPage = lazy(() => import("../../pages/panel/reports"));

const RecordsPage = lazy(() => import("../../pages/panel/records"));
const RegionsPage = lazy(() => import("../../pages/panel/records/regions"));
const CollaboratorsPage = lazy(() =>
    import("../../pages/panel/records/collaborators")
);
const TeamPage = lazy(() => import("../../pages/panel/records/team"));

const CharacteristicsPage = lazy(() =>
    import("../../pages/panel/records/characteristics/index")
);
const EquipamentsPage = lazy(() =>
    import("../../pages/panel/records/equipaments/index")
);
const ServiceTypePage = lazy(() =>
    import("../../pages/panel/records/service_type/index")
);
const OcorrencesPage = lazy(() =>
    import("../../pages/panel/records/ocorrences/index")
);
const PublicBuildings = lazy(() =>
    import("../../pages/panel/records/publicBuildings/index")
);

const FiltersAreaPage = lazy(() => import("../../pages/panel/filters/area"));
const FiltersRegionsPage = lazy(() =>
    import("../../pages/panel/filters/regions")
);
const FiltersTeamsPage = lazy(() => import("../../pages/panel/filters/teams"));

const LoginLayout = lazy(() => import("../layouts/Login/Index"));
const DefaultLayout = lazy(() => import("../layouts/Default/Index"));

const AdminRouter = () => (
    <Suspense fallback={<ModalOverlay />}>
        <Switch>
            <RouteWithLayout
                component={DashBoardPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/"
            />

            <RouteWithLayout
                component={LoginPage}
                exact={false}
                isPrivate={false}
                layout={LoginLayout}
                path="/login"
            />

            <RouteWithLayout
                component={RecoverPage}
                exact={false}
                isPrivate={false}
                layout={LoginLayout}
                path="/recover"
            />

            <RouteWithLayout
                component={MapPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel"
            />

            <RouteWithLayout
                component={DashBoardPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel/dashboard"
            />

            <RouteWithLayout
                component={OrderPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel/order"
            />

            <RouteWithLayout
                component={ReportsPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel/reports"
            />

            <RouteWithLayout
                component={RecordsPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel/records"
            />

            <RouteWithLayout
                component={RegionsPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel/records/regions"
            />

            <RouteWithLayout
                component={CollaboratorsPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel/records/collaborators"
            />

            <RouteWithLayout
                component={TeamPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel/records/team"
            />

            <RouteWithLayout
                component={CharacteristicsPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel/records/characteristics"
            />

            <RouteWithLayout
                component={EquipamentsPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel/records/equipaments"
            />

            <RouteWithLayout
                component={ServiceTypePage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel/records/servicetype"
            />

            <RouteWithLayout
                component={OcorrencesPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel/records/ocorrences"
            />

            <RouteWithLayout
                component={PublicBuildings}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel/records/public-buildings"
            />

            <RouteWithLayout
                component={FiltersAreaPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel/filters/area"
            />

            <RouteWithLayout
                component={FiltersRegionsPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel/filters/regions"
            />

            <RouteWithLayout
                component={FiltersTeamsPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/panel/filters/teams"
            />

            <RouteWithLayout
                component={SecretariatPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/admin/secretariat"
            />

            {/* <Redirect to="/not-found" /> */}
        </Switch>
    </Suspense>
);
export default AdminRouter;
