import { ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

interface ButtonProps {
    theme: {
        shadow: {
            custom: string
        }
        radius: {
            xs: string
            sm: string
            md: string
        }
    }
    disableElevation: string
    radius: keyof ButtonProps['theme']['radius'];
    fontcolor: string
    variant: string
    fontSize: string
    capitalize: string
};

export const StyledButton = styled(Button)<ButtonProps>`
    padding: 10px !important;

    ${({ theme, disableElevation }: ButtonProps) =>
        !disableElevation && `box-shadow: ${theme.shadow.custom} !important;`}

    ${({ theme, radius }: ButtonProps) =>
        radius && `border-radius: ${theme.radius[radius]} !important;`}

    ${({ fontcolor, variant }: ButtonProps) =>
        fontcolor &&
        `color: ${fontcolor} !important;${
            variant === 'outlined' ? `border:1px solid ${fontcolor} !important;` : ''
        }`}
    
    ${({ fontSize }: ButtonProps) => fontSize && `font-size: ${fontSize} !important;`}

    ${({ capitalize }: ButtonProps) =>
        capitalize === 'true' &&
        `font-weight: normal !important;text-transform: capitalize !important;`}
`;

interface props {
    children: ReactNode | string
    icon?: ReactNode
    variant?: string
    color?: string
    fontcolor?: string
    fontSize?: number
    capitalize?: string
    disableElevation?: boolean
    radius?: string
    fullWidth?: boolean
    type?: string
    onClick?: () => void
}

const Btn = ({ 
    children,
    icon,
    variant,
    color,
    fontcolor,
    fontSize,
    capitalize,
    disableElevation,
    radius,
    fullWidth,
    type,
    onClick,
}: props) => (
    <StyledButton
        onClick={onClick}
        elevation={0}
        variant={variant}
        color={color}
        fontcolor={fontcolor}
        fontSize={fontSize}
        capitalize={capitalize}
        disableElevation={disableElevation}
        fullWidth={fullWidth}
        radius={radius}
        type={type}
    >
        {icon ? <>{icon} &nbsp;</> : null}
        {children}
    </StyledButton>
);

export default Btn;
