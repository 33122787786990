import React, { ReactNode } from 'react';
import { LegacyProviders } from '../../js/routes/index'; // ! Legacy
import ConfirmDialog from '../components/dumb/modal/confirmation-dialog/Index';

const Theme = ({ children }: { children: ReactNode }) => (
  <LegacyProviders>
    <ConfirmDialog />
    {children}
  </LegacyProviders>
);

export default Theme;
