import { Polygon, Marker, Polyline, useJsApiLoader, GoogleMap, DrawingManager, InfoWindow } from '@react-google-maps/api';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { LatLng } from '../../../types/LatLng';

interface HOCWithDetailsProps {
    position: LatLng
    children: ReactNode
    onRenderShape: (onOpen: Dispatch<SetStateAction<LatLng | null>>) => ReactNode
};

const HOCWithDetails = ({ position, onRenderShape, children }: HOCWithDetailsProps) => {
    const [selectedMarker, setSelectedMarker] = useState<LatLng | null>(null);

    return (
        <>
            {onRenderShape(setSelectedMarker)}

            {selectedMarker && (
                <InfoWindow
                    position={{
                        lat: position.lat,
                        lng: position.lng,
                    }}
                    onCloseClick={() => setSelectedMarker(null)}
                >
                    <div>
                        {children}
                    </div>
                </InfoWindow>
            )}
        </>
    );
};

interface MarkerWithDetailsProps {
    position: LatLng
    children: ReactNode
    icon?: {
        url: string,
        scaledSize: { height: number, width: number },
    }
};

const MarkerWithDetails = ({ position, children, icon }: MarkerWithDetailsProps) => (
    <>
        <HOCWithDetails
            onRenderShape={(onOpen) => (
                <Marker 
                    position={position} 
                    onClick={() => onOpen(position)} 
                    icon={icon as any} 
                />
            )}
            position={position}
        >
            {children}
        </HOCWithDetails>
    </>
);

export { Polygon, Marker, Polyline, useJsApiLoader, GoogleMap, DrawingManager, InfoWindow, MarkerWithDetails };
