import React, { useState, useEffect } from 'react';
import Modal from '../index';
import Button from '../../button/index';
import Box from '../../box/index';

interface ConfirmDialogState {
    title: string;
    subTitle: string;
    onOK: () => void;
}

const ConfirmDialog: React.FC = () => {
    const [onOpen, setOpen] = useState<boolean>(false);
    const [state, setState] = useState<ConfirmDialogState>({
        title: '',
        subTitle: '',
        onOK: () => null,
    });

    useEffect(() => {
        const handleConfirmDialog = (event: CustomEvent) => {
            setOpen(true);
            setState(event.detail);
        };

        document.addEventListener('@ConfirmDialog', handleConfirmDialog as EventListener);

        return () => {
            document.removeEventListener('@ConfirmDialog', handleConfirmDialog as EventListener);
        };
    }, []);

    return (
        <>
            <Modal open={onOpen} onClose={() => setOpen(false)}>
                <>
                    <h3>{state.title}</h3>
                    <p>{state.subTitle}</p>

                    <Box mt={3} display="flex" justifyContent="flex-end" style={{ gap: 4 }}>
                        <Button
                            color="primary"
                            fontcolor="#00458B"
                            fontSize={14}
                            capitalize="true"
                            fullWidth={false}
                            disableElevation={true}
                            radius="xs"
                            onClick={() => setOpen(false)}
                        >
                            Cancelar
                        </Button>

                        <Button
                            color="primary"
                            fontcolor="#00458B"
                            fontSize={14}
                            capitalize="true"
                            fullWidth={false}
                            disableElevation={true}
                            radius="xs"
                            onClick={() => {
                                state.onOK();
                                setOpen(false);
                            }}
                        >
                            Confirmar
                        </Button>
                    </Box>
                </>
            </Modal>
        </>
    );
};

interface ConfirmParams {
    title: string;
    subTitle: string;
    onOK: () => void;
}

export const Confirm = ({ title, subTitle, onOK }: ConfirmParams) => {
    document.dispatchEvent(new CustomEvent('@ConfirmDialog', {
        detail: {
            title,
            subTitle,
            onOK,
        },
    }));
};

export default ConfirmDialog;
