import React from 'react';
import { Redirect, Route } from 'react-router-dom';

interface RouteWithLayoutProps {
    layout: React.ComponentType<any>;
    component: React.ComponentType<any>;
    isPrivate: boolean;
    exact: boolean;
    path: string;
}

const RouteWithLayout: React.FC<RouteWithLayoutProps> = ({
    layout: Layout,
    component: Component,
    isPrivate,
    ...rest
}) => (
    <Route
        {...rest}
        render={(matchProps: any) => {
            if(isPrivate && !sessionStorage.getItem('user')) {
                return <Redirect to={{ pathname: '/login' }} />
            }

            return (
                <Layout user={JSON.parse(sessionStorage.getItem('user') as string)}>
                    <Component {...matchProps} />
                </Layout>
            )
        }}
    />
);

export default RouteWithLayout;
